@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
$image_path: '~material-design-lite/src/images';
@import '~material-design-lite/src/material-design-lite';

/* MDL Modifications */

/* Input fields */

$rew-input-text-vertical-padding: $rew-input-text-vertical-spacing - 4px;
.mdl-textfield.mdl-textfield {
  margin-top: -3px;

  &.mdl-textfield--floating-label.is-focused,
  &.mdl-textfield--floating-label.is-dirty {
    .mdl-textfield__label {
      padding-top: 3px;
    }
  }
}

.rewire-external-extensions .mdl-textfield--floating-label {
  &.is-dirty.is-focused .mdl-textfield__label {
    color: $input-text-highlight-color;
  }

  &.is-dirty .mdl-textfield__label {
    color: $rew-dirty-textfield-label-color;
  }

  // Override MDL's default label's error color
  &.is-invalid .mdl-textfield__label {
    color: $rew-dirty-textfield-label-color;
    font-size: $rew-text-field-font-size;
  }

  &.is-invalid.is-dirty,
  &.is-invalid.is-focused {
    .mdl-textfield__label {
      font-size: $rew-text-floating-label-font-size;
    }
  }
}

.mdl-textfield__input {
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .rewire-external-extensions & {
    color: $rew-textfield-input-color;

    // This alignment is required since the input is 1px lower than the placeholder, and rew-input-additions are aligned to the input.
    //margin-top: -1px;
    &.mdl-textfield__input--positive {
      color: $rew-text-color-positive;
    }
  }

  .mdl-textfield--rewire-padding-light & {
    padding-right: 40px;
  }

  .mdl-textfield--rewire-padding-medium & {
    padding-right: 50px;
  }
}

.mdl-textfield,
.mdl-selectfield {
  &.mdl-textfield--full-size {
    width: 100%;
  }

  &.is-invalid .mdl-textfield__rewire-comment {
    visibility: hidden;
  }

  & .mdl-textfield__rewire-comment {
    color: $rew-gray;
    position: absolute;

    // same as .mdl-textfield__error
    margin-top: 3px;
  }

  .mdl-textfield__error,
  .mdl-textfield__rewire-comment {
    font-size: $rew-font-size-10;
    line-height: $rew-font-size-10;
  }
}

/* Buttons */
.mdl-button {
  font-family: $rew-font-family-regular;
  text-transform: uppercase;

  &.mdl-button--rewire-wide {
    width: 100%;
    border-left: none;
  }
  &.mdl-button--rewire-full {
    width: 100%;
    height: $rew-button-height;

    color: $rew-button-text-color;

    border-radius: $rew-button-border-radius;

    font-size: $rew-button-font-size;
    line-height: $rew-button-height;
  }

  &.mdl-button--rewire-positive {
    background-color: $rew-button-background-positive-color;
  }

  &.mdl-button--rewire-negative {
    background-color: $rew-button-background-negative-color;
    box-shadow: none;
  }

  &.mdl-button--fab {
    box-shadow: none;
  }

  &.mdl-button--fab.mdl-button--rewire-huge-fab {
    height: 190px;
    min-width: 190px;
    width: 190px;

    font-size: 39px;
  }

  &.mdl-button--rewire-text-left {
    text-align: left;
  }
  &.mdl-button--rewire-nocase {
    text-transform: none;
  }

  &.mdl-button--raised:active,
  &.mdl-button--raised:focus:not(:active) {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.18), 0 4px 5px rgba(0, 0, 0, 0.36);
  }
}
