@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-modal-page,
.rew-modal--full {
  overflow-y: auto;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  min-width: 300px;
}

.rew-modal-page {
  font-family: $rew-font-family-regular;

  background-repeat: no-repeat;
  background-size: 100% 100%;

  padding: 10px 23px;
}

.wrapper {
  margin: auto;
}

.rew-modal-page--blue-backround {
  background: linear-gradient(#284a5d, #091523);

  width: 100%;
  height: 100%;
  position: absolute; // for calculator
}

.rew-modal-page {
  background-position-y: 90%;
  background-size: 150% 40%;

  width: 100%;
  height: 100%;

  transition: none;
  transition-duration: 0;
}

@media screen and (max-height: 600px) {
  /* Specific to this particular image */
  .rew-modal-page {
    background-position-y: 300px;
  }
}

.rew-modal-page--wrapper {
  max-width: 560px;
  margin: auto;
}

.rew-modal-page__header {
  width: 100%;
  margin-bottom: 10px;

  max-height: 120px; // iphone 4 ios 7.2.1

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.rew-modal-page__logo {
  display: block;
  height: 100px;
}
