@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
// Variables
@import '~@rewire/ionic/scss/mixins';
@import '~@rewire/ionic/scss/variables';

// Base
@import '~@rewire/ionic/scss/scaffolding';

// Components
@import '~@rewire/ionic/scss/action-sheet';
@import '~@rewire/ionic/scss/backdrop';
@import '~@rewire/ionic/scss/bar';
@import '~@rewire/ionic/scss/tabs';
@import '~@rewire/ionic/scss/menu';
@import '~@rewire/ionic/scss/modal';
@import '~@rewire/ionic/scss/popover';
@import '~@rewire/ionic/scss/popup';
@import '~@rewire/ionic/scss/loading';
@import '~@rewire/ionic/scss/items';
@import '~@rewire/ionic/scss/list';
@import '~@rewire/ionic/scss/badge';
@import '~@rewire/ionic/scss/slide-box';
@import '~@rewire/ionic/scss/refresher';
@import '~@rewire/ionic/scss/spinner';

// Forms
/* "form",
  "checkbox",
  "toggle",
  "radio",
  "range",
  "select",
  "progress", */

// Buttons
/* "button",
  "button-bar", */

// Util
@import '~@rewire/ionic/scss/grid';
@import '~@rewire/ionic/scss/util';
@import '~@rewire/ionic/scss/platform';

// Animations
@import '~@rewire/ionic/scss/animations';
@import '~@rewire/ionic/scss/transitions';

.rewire-external-extensions {
  & .tabs-top.tabs-striped {
    & .tab-nav.tabs {
      background: $rew-primary-dark;
      border: 0 !important;

      & .tab-item {
        & {
          color: $rew-white;
          background-color: $rew-primary-dark;
          font-family: $rew-font-family-regular;
          opacity: 1;
          text-transform: uppercase;

          flex: 1 1 auto;
        }

        &:active {
          background-color: $rew-primary-dark-active;
        }

        &.tab-item-active {
          & {
            margin-top: 0px;
            border: 0 !important;
            background-color: $rew-primary-dark-active;
          }

          & .tab-title {
            //transition: border-bottom-color 1s ease;
            //border-bottom: solid 2px $rew-white !important ;
            //padding-bottom: 5px;

            //override mdl
            margin-top: 0px;
          }
        }

        &.activated {
          border-color: transparent;
          margin-top: 0px;

          .tab-title {
            margin-top: 0px;
          }
        }
      }
    }
  }
}

.tabs-top.tabs-striped .tab-nav.tabs .tab-item {
  .rewire-external-extensions .rew-main-view & .tab-item-active {
    background-color: #5aadda;
  }
  .rewire-external-extensions &.rew-settings-tab:hover {
    cursor: default;
  }
}

ion-content {
  & {
    background-color: $rew-alto;
  }

  & .scroll {
    height: 100%;
  }
}

#family-account-tabs {
  & .tab-nav.tabs {
    background-color: $family-account-background;

    & .tab-item {
      & {
        background-color: $family-account-background;
      }

      &:active {
        background-color: $family-account-background-active;
      }

      &.tab-item-active {
        & {
          background-color: $family-account-background-active;
        }
      }
    }
  }
}

.family-account-mode {
  .bar {
    background-color: $family-account-background !important;
  }
}
