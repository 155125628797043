@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
@import '~@rewire/ionic/scss/variables';

@include not-phone {
  .onboarding {
    &__screen {
      margin-top: 45px;
    }
  }
}

// in iOS, transparent status bar is overlapping the application’s WebView.
.platform-ios {
  .onboarding {
    &__screen {
      margin-top: $ios-statusbar-height;
    }
  }
}
